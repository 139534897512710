import React from "react"
import Navigation from "../navigation/navigation"
import logoMain from "../../../static/icons/eco-logo-main-new.svg"
// import Notice from "../notice/notice"
// import Icon1 from "../../../static/icons/call.svg"
// import Icon2 from "../../../static/icons/mobile-phone.svg"

export default function Header({ children }) {
  return <>
  <header className="header">
    {/* <Notice><strong>Water Restriction Notice:</strong> <a href="water-restriction">learn more...</a></Notice> */}
    <div className="header-container">
      <div className="container">
        <div className="header-innerContainer">
        <div className="header-logoContainer">
          <a href="/"><img className="header-logoMain" src={logoMain} alt="Eco Wash Solutions" /></a>
        </div>
        <Navigation />
        </div>
      </div>
    </div>
    <div className="contact-container">
      <a href="tel:021 724 555" className="contact-item">Auckland: 021 724 555</a>
      {/* <a href="tel:021 275 8575" className="contact-item">Hamilton: 021 275 8575</a> */}

      {/* <a href="tel:09 215 4117" className="contact-item"><img className="icon-tiny" src={Icon2} alt="" loading="lazy" /> 09 215 4117</a> */}
    </div>
  </header>
  </>
}